var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"taxInfoForm",staticClass:"multi-col-validation mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"id":"nationality","items":_vm.countries,"label":_vm.$t('formLabelNationality'),"rules":[_vm.validators.required],"dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.taxData.nationality),callback:function ($$v) {_vm.$set(_vm.taxData, "nationality", $$v)},expression:"taxData.nationality"}})],1),_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-text-field',{attrs:{"id":"occupation","label":_vm.$t('formLabelProfession'),"placeholder":_vm.$t('formLabelProfession'),"rules":[_vm.validators.required],"dense":"","outlined":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.taxData.occupation),callback:function ($$v) {_vm.$set(_vm.taxData, "occupation", $$v)},expression:"taxData.occupation"}})],1),_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-select',{attrs:{"id":"tax-residence","items":_vm.countries,"label":_vm.$t('formLabelTaxResidence'),"rules":[_vm.validators.required],"dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.taxData.taxResidence),callback:function ($$v) {_vm.$set(_vm.taxData, "taxResidence", $$v)},expression:"taxData.taxResidence"}})],1),_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"1","color":"info"}},[_c('v-select',{attrs:{"id":"tax-class-factor","items":_vm.taxClassOptions,"label":_vm.$t('formLabelTaxClass'),"placeholder":_vm.$t('formLabelTaxClass'),"rules":[_vm.validators.required],"dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.taxData.taxClass),callback:function ($$v) {_vm.$set(_vm.taxData, "taxClass", $$v)},expression:"taxData.taxClass"}})],1)],1),_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"2","color":"info"}},[_c('v-select',{attrs:{"id":"child-allowance","items":_vm.childAllowanceOptions,"label":_vm.$t('formLabelChildTaxAllowance'),"placeholder":_vm.$t('formPlaceholderChildTaxAllowance'),"rules":[_vm.validators.numberRequired],"dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.taxData.childAllowance),callback:function ($$v) {_vm.$set(_vm.taxData, "childAllowance", $$v)},expression:"taxData.childAllowance"}})],1)],1),_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-radio-group',{attrs:{"row":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"label-custom mr-5"},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"3","color":"info"}},[_vm._v(_vm._s(_vm.$t('formLabelChurchTax')))])],1)]},proxy:true}]),model:{value:(_vm.taxData.churchTax),callback:function ($$v) {_vm.$set(_vm.taxData, "churchTax", $$v)},expression:"taxData.churchTax"}},[_c('v-radio',{attrs:{"id":"church-tax","label":_vm.$t('yes'),"value":true}}),_c('v-radio',{attrs:{"label":_vm.$t('no'),"value":false}})],1)],1),_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-select',{attrs:{"id":"insurance-typ","items":_vm.healthInsuranceOptions,"rules":[_vm.validators.required],"label":_vm.$t('formLabelHealthInsurance'),"placeholder":_vm.$t('formLabelHealthInsurance'),"dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.taxData.healthInsuranceType),callback:function ($$v) {_vm.$set(_vm.taxData, "healthInsuranceType", $$v)},expression:"taxData.healthInsuranceType"}})],1),(!_vm.fetchExistingProvider && _vm.taxData.healthInsuranceType !== _vm.HEALTH_INSURANCE_PRIVATE)?_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"4","color":"info"}},[_c('v-autocomplete',{attrs:{"items":_vm.healthInsuranceProviders,"label":_vm.$t('healthInsuranceProvider'),"placeholder":_vm.$t('healthInsuranceProvider'),"rules":[_vm.validators.required],"item-text":"providerName","return-object":"","dense":"","outlined":"","hide-details":""},on:{"change":_vm.handleHealthInsuranceProviderSelection},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}},{key:"selection",fn:function(data){return [[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.taxData.healthInsuranceProvider))]),_vm._v(" —"+_vm._s(_vm.taxData.healthInsuranceContributionRate)+"% ")])]]}},{key:"item",fn:function(data){return [[(data)?_c('span',[_c('strong',[_vm._v(_vm._s(data.item.providerName))]),_vm._v("—"+_vm._s(data.item.providerContribution)+"% ")]):_vm._e()]]}}],null,false,3881965310),model:{value:(_vm.taxData.healthInsuranceProvider),callback:function ($$v) {_vm.$set(_vm.taxData, "healthInsuranceProvider", $$v)},expression:"taxData.healthInsuranceProvider"}},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.$t('myBavNoResults')))])])],2)],1)],1):_vm._e(),(_vm.taxData.healthInsuranceType === _vm.HEALTH_INSURANCE_PRIVATE)?[_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"A","color":"info"}},[_c('v-text-field',{attrs:{"id":"contribution-private","dense":"","outlined":"","required":"","rules":[_vm.validators.required],"label":_vm.$t('formLabelTotalCont')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.taxData.healthInsurancePrivateContribution),callback:function ($$v) {_vm.$set(_vm.taxData, "healthInsurancePrivateContribution", $$v)},expression:"taxData.healthInsurancePrivateContribution"}})],1)],1),_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"B","color":"info"}},[_c('v-text-field',{attrs:{"id":"care-insurance","rules":[_vm.validators.required],"dense":"","outlined":"","required":"","label":_vm.$t('formLabelCareInsurance')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.taxData.careInsuranceContribution),callback:function ($$v) {_vm.$set(_vm.taxData, "careInsuranceContribution", $$v)},expression:"taxData.careInsuranceContribution"}})],1)],1),_c('v-col',{staticClass:"mb-2",attrs:{"md":"12","cols":"12"}},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"C","color":"info"}},[_c('v-text-field',{attrs:{"id":"income-tax","dense":"","outlined":"","required":"","rules":[_vm.validators.required],"label":_vm.$t('formLabelTaxLevy')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.taxData.incomeTaxContribution),callback:function ($$v) {_vm.$set(_vm.taxData, "incomeTaxContribution", $$v)},expression:"taxData.incomeTaxContribution"}})],1)],1)]:_vm._e(),_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"5","color":"info"}},[_c('v-text-field',{attrs:{"id":"gross-income","type":"number","rules":typeof _vm.taxData.grossIncome === 'number' ? [_vm.validators.numberRequired] : [_vm.validators.required],"dense":"","outlined":"","onwheel":"this.blur()","label":_vm.$t('formLabelGrossWage')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.taxData.grossIncome),callback:function ($$v) {_vm.$set(_vm.taxData, "grossIncome", $$v)},expression:"taxData.grossIncome"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menuref",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"badge-custom",attrs:{"top":"","overlap":"","content":"6","color":"info"}},[_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"employment-start-date","label":_vm.$t('labelEmployeeStartDate'),"readonly":"","outlined":"","dense":"","prepend-inner-icon":_vm.icons.mdiCalendar,"value":_vm.taxData.employmentStartDate ? _vm.moment(_vm.taxData.employmentStartDate).format('DD.MM.YYYY') : null,"rules":[_vm.validators.required]},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,true)},'v-text-field',attrs,false),on))],1)]}}])},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1950-01-01","color":"primary"},on:{"change":_vm.save},model:{value:(_vm.taxData.employmentStartDate),callback:function ($$v) {_vm.$set(_vm.taxData, "employmentStartDate", $$v)},expression:"taxData.employmentStartDate"}})],1)],1)],2)],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{attrs:{"md":"6","cols":"6"}},[_c('v-card',{staticClass:"mb-8 ml-5",attrs:{"outlined":""}},[_c('v-img',{attrs:{"src":require("@/assets/images/request-offer/example2.svg"),"alt":"logo","max-height":"611"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"mt-5",class:[
      { 'd-flex justify-space-between': !_vm.$vuetify.breakpoint.smAndDown },
      { 'd-flex flex-column justify-center': _vm.$vuetify.breakpoint.smAndDown } ]},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"request-offer__btn mb-5",class:[
        {
          'request-offer__btn request-offer__btn--mobile': _vm.$vuetify.breakpoint.smAndDown,
        } ],attrs:{"color":"primary","type":"submit","disabled":!_vm.isFormValid}},[_vm._v(" "+_vm._s(_vm.$t('stepperButtonContinue2'))+" ")]):_vm._e(),_c('v-btn',{staticClass:"request-offer__btn--white",class:[
        {
          'request-offer__btn request-offer__btn--mobile': _vm.$vuetify.breakpoint.smAndDown,
        } ],attrs:{"outlined":""},on:{"click":function($event){return _vm.$emit('stepBack')}}},[_vm._v(" "+_vm._s(_vm.$t('stepperButtonBack2'))+" ")]),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"request-offer__btn",attrs:{"disabled":!_vm.isFormValid,"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('stepperButtonContinue2'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }