<template>
  <div class="py-sm-10 py-5 my-bav">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-card class="v-card--no-border" color="transparent">
          <h1 class="font-weight-bold mt-10 mb-5 text-center">
            {{ $t('menuMyBav') }}
          </h1>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-10">
      <v-col cols="12" sm="12" md="11" lg="10">
        <v-tabs
          v-model="currentTab"
          grow
          active-class="my-bav__tab--active"
          :icons-and-text="!$vuetify.breakpoint.smAndDown"
          :hide-slider="$vuetify.breakpoint.smAndDown"
        >
          <v-tab>
            <span class="my-bav__tab-text">
              {{ $t('myBavDetailsTitle') }}
            </span>
            <v-icon v-if="!$vuetify.breakpoint.smAndDown" size="16">{{ icons.mdiFileDocumentEdit }}</v-icon>
          </v-tab>
          <v-tab>
            <span class="my-bav__tab-text">
              {{ $t('menuDocuments') }}
            </span>
            <v-icon v-if="!$vuetify.breakpoint.smAndDown" size="16">{{ icons.mdiFileDocumentMultiple }}</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab" touchless>
          <v-tab-item>
            <d-request-offer-stepper v-if="contractStage === 1 || contractStage === 7"> </d-request-offer-stepper>
            <d-my-bav
              v-else
              :contractDetails="contractDetails"
              :changeRequests="changeRequests"
              :largeTiles="largeTiles"
            ></d-my-bav>
          </v-tab-item>
          <v-tab-item>
            <meine-documents></meine-documents>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiFileDocumentMultiple, mdiFileDocumentEdit } from '@mdi/js';
import { ref, getCurrentInstance, computed, reactive } from '@vue/composition-api';
import DRequestOfferStepper from '@/components/request-offer/Stepper.vue';
import DMyBav from './tabs/MyBav.vue';

import MeineDocuments from './tabs/MeineDocuments';
import {
  CONTRACT_TRANSLATION_MAP,
  CONTRACT_APPLICATION,
  CONTRACT_EXISTING,
  CONTRACT_OPTED_OUT,
  CONTRACT_ONBOARDED,
  CONTRACT_OFFER_REQUESTED,
  CONTRACT_OFFER_ACCEPTED,
  CONTRACT_SIGNED,
  CONTRACT_SUSPENDED,
} from '../../../constants';

export default {
  components: {
    MeineDocuments,
    DRequestOfferStepper,
    DMyBav,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const currentTab = ref(0);
    const contractDetails = ref({ show: false });
    const changeRequests = ref({ show: false });
    const largeTiles = ref({ show: false });

    const contractStatus = computed(() => {
      return CONTRACT_TRANSLATION_MAP[vm.$store.state.auth.contract.contractStatus];
    });

    const contractStage = computed(() => {
      switch (vm.$store.state.auth.contract.contractStatus) {
        case CONTRACT_ONBOARDED:
          return 1;
        case CONTRACT_OFFER_REQUESTED:
          contractDetails.value.show = true;
          changeRequests.value.show = false;
          largeTiles.value.show = false;
          return 2;
        case CONTRACT_OFFER_ACCEPTED:
          contractDetails.value.show = true;
          changeRequests.value.show = false;
          largeTiles.value.show = false;
          return 3;
        case CONTRACT_APPLICATION:
          contractDetails.value.show = true;
          changeRequests.value.show = false;
          largeTiles.value.show = false;
          return 4;
        case CONTRACT_SIGNED:
          contractDetails.value.show = true;
          changeRequests.value.show = true;
          largeTiles.value.show = false;
          return 5;
        case CONTRACT_EXISTING:
          contractDetails.value.show = false;
          changeRequests.value.show = false;
          largeTiles.value.show = true;
          largeTiles.value.introHeading = 'largeTilesIntroOld';
          return 6;
        case CONTRACT_OPTED_OUT:
          contractDetails.value.show = false;
          changeRequests.value.show = false;
          largeTiles.value.show = false;
          largeTiles.value.introHeading = 'largeTilesIntroDeclined';
          return 7;
        case CONTRACT_SUSPENDED:
          contractDetails.value.show = true;
          largeTiles.value.show = false;
          changeRequests.value.show = true;
          return 8;
      }
    });

    return {
      currentTab,
      contractStatus,
      contractStage,
      contractDetails,
      changeRequests,
      largeTiles,

      icons: {
        mdiFileDocumentEdit,
        mdiFileDocumentMultiple,
      },
    };
  },
};
</script>
<style lang="scss">
.my-bav {
  padding-top: 33px !important;
  &__status {
    position: fixed;
    width: 100%;
    height: 42px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #f9b60d;
    z-index: 5000;
  }
  .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
    > .v-slide-group__wrapper
    > .v-tabs-bar__content
    > .v-tab:first-of-type {
    margin-left: unset !important;
  }

  .v-tabs-bar {
    height: 61px !important;
  }

  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tab {
    font-size: 14px !important;
    text-transform: none !important;
    padding-top: 13px;
    padding-bottom: 8px;
    letter-spacing: normal !important;
  }
  &-next {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  &__tab {
    &-text {
      color: #898989;
      margin-top: 9px;
    }
    &--active {
      background-color: rgba(108, 189, 255, 0.5);
      font-weight: bold;
      .my-bav__tab-text {
        color: #2830ce;
        font-weight: bold;
      }
    }
  }
}
.v-tabs-overlay {
  position: relative;
}
.v-tabs-overlay::after {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 4;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  pointer-events: none; /* Pass events through the "fade" layer */
}
.scrolling::after {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .my-bav {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
      word-break: break-word;
      white-space: pre-wrap;
    }
    .v-tabs-bar {
      height: 50px !important;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
    .v-tab {
      max-width: 50px;
      line-height: 12px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    &__tab-text {
      max-width: 95px;
      font-size: 10px !important;
      margin: 0 !important;
    }
    &-tabs {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 959px) {
  .v-divider {
    transform: scale(0.4);
  }
  .v-stepper__step {
    padding: 24px 19px;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 110px;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;
    justify-content: center;
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
      margin-right: 5px;
    }
  }
}
</style>
