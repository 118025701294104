<template>
  <div>
    <v-card v-if="contractDetails.show === true" class="py-10 mb-10 mt-10">
      <p class="text-center">
        {{ $t('headlineMonthlyContribution') }}
      </p>
      <vue-apex-charts
        v-if="userContract"
        type="donut"
        height="350"
        :options="donutChart.chartOptions"
        :series="[userContract.totalContribution]"
      />
    </v-card>

    <v-expansion-panels accordion focusable class="my-bav__panels">
      <v-expansion-panel v-if="contractDetails.show">
        <v-expansion-panel-header class="primary--text border--light">
          {{ $t('myContractData') }}
          <template v-slot:actions>
            <v-icon color="primary"> $expand </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="border--light">
          <d-combined-data-table
            v-if="userContract"
            :personalData="userContract.personalDetails"
            :taxData="userContract.taxDetails"
            :contractData="userContract"
            :isInStepper="false"
          ></d-combined-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="largeTiles.show">
      <h2 class="mb-15 mt-15 text-center text--blue">{{ $t(largeTiles.introHeading) }}</h2>
      <d-large-tiles class=""></d-large-tiles>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api';
import { mdiCached, mdiCheckBold, mdiAlert } from '@mdi/js';
import VueApexCharts from 'vue-apexcharts';
import { getEmployeeById, getContractByEmployeeId } from '@/api/employee';
import { getHealthInsuranceProviderById } from '@/api/health-insurance';
import DCombinedDataTable from '@/components/employee-data/CombinedDataTable.vue';
import DLargeTiles from '@/components/tiles/LargeTiles.vue';
import { BENEFICIARY_LEGAL_SUCCESSION } from '@/constants';

export default {
  components: {
    DCombinedDataTable,
    VueApexCharts,
    DLargeTiles,
  },
  props: ['contractDetails', 'largeTiles'],
  setup() {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const userDetails = ref(null);
    const userContract = ref(null);
    const fetchExistingProvider = ref(false);

    const getDetails = (id) => {
      getEmployeeById(id).then((res) => {
        userDetails.value = res.data;
        userDetails.value.taxDetails = {
          ...userDetails.value.taxDetails,
        };

        getContractByEmployeeId(id)
          .then((contract) => {
            userContract.value = contract;

            if (userDetails.value.taxDetails.healthInsuranceId) {
              fetchExistingProvider.value = true;
              getHealthInsuranceProviderById(userDetails.value.taxDetails.healthInsuranceId)
                .then((provider) => {
                  userContract.value.taxDetails.healthInsuranceProvider = provider.data.providerName;
                  userContract.value.taxDetails.healthInsuranceContributionRate = provider.data.providerContribution;

                  vm.$store.commit('setContract', contract);
                })
                .finally(() => {
                  fetchExistingProvider.value = false;
                });
            }
          })
          .catch(() => {
            userContract.value = {
              beneficiaryDetails: {
                beneficiaryType: BENEFICIARY_LEGAL_SUCCESSION,
                beneficiaryFirstName: '',
                beneficiaryLastName: '',
              },
              employeeContribution: 40,
            };
          });
      });
    };

    getDetails(userData.id);

    const chartColors = {
      donut: {
        series1: '#0404BC',
      },
    };
    return {
      userContract,
      userDetails,
      userData,
      // icon
      chartColors,
      icons: { mdiCached, mdiCheckBold, mdiAlert },
    };
  },
  computed: {
    donutChart() {
      return {
        series: [211],

        chartOptions: {
          legend: {
            show: false,
            position: 'bottom',
            fontSize: '50px',
            fontFamily: '"Neue Haas Grotesk Text Pro", sans-serif',
          },
          colors: [this.chartColors.donut.series1, this.chartColors.donut.series2],
          dataLabels: {
            enabled: false,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1rem',
                    fontFamily: '"Neue Haas Grotesk Text Pro", sans-serif',
                    fontWeight: 'bold',
                  },
                  value: {
                    show: true,
                    fontSize: '1rem',
                    fontFamily: '"Neue Haas Grotesk Text Pro", sans-serif',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${`${parseInt(val)} ` + `€`} `;
                    },
                  },
                  total: {
                    fontFamily: '"Neue Haas Grotesk Text Pro", sans-serif',
                    show: false,
                    fontSize: '1rem',
                    formatter() {
                      return '300 ' + '€';
                    },
                  },
                },
              },
            },
          },
          labels: ['Total'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      };
    },
  },
};
</script>
<style lang="scss">
.my-bav {
  &__panels {
    .v-expansion-panel-header--active {
      background-color: #f3f3f3 !important;
      border-bottom: none !important;
      &:before {
        background-color: #f3f3f3 !important;
      }
    }
  }
}
</style>
