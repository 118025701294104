<template>
  <v-stepper v-model="activeStep" flat class="transparent custom-header px-1 py-2" alt-labels height="auto">
    <div v-if="activeStep === 1 && false" class="offer-type">
      <v-radio-group v-model="isDirectSetup" color="white" inline hide-details :ripple="false">
        <v-radio inline hide-details :label="$t('setupBavWithOfferRadio')" :value="false"></v-radio>
        <v-radio inline hide-details :label="$t('setupBavDirectlyRadio')" :value="true"></v-radio>
      </v-radio-group>
    </div>
    <p v-if="activeStep === 2 && !isDirectSetup" class="text-center mt-7">{{ $t('enterInformation2') }}</p>
    <p v-if="activeStep === 2 && isDirectSetup" class="text-center mt-7">{{ $t('enterInformation2Direct') }}</p>
    <p v-if="activeStep === 3" class="text-center mt-7">
      {{ $t('enterInformation3') }}
    </p>
    <p v-if="activeStep === 4" class="text-center mt-7">
      {{ $t('enterInformation4') }}
    </p>

    <!-- Header -->
    <v-stepper-header :disabled="loadings.loading1">
      <!-- Header: Step 1 -->
      <v-stepper-step :complete="activeStep > 1" step="1" @click="activeStep = 1">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-sm font-weight-semibold"
            :class="stepValidationResult[1] ? '' : 'cursor-disabled'"
            >{{ $t('personalProfileHeadingPersonal') }}</span
          >
          <span v-else :class="stepValidationResult[1] ? '' : ''">{{ $t('personalProfileHeadingPersonal') }}</span>
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-xs text--secondary"
            :class="stepValidationResult[1] ? '' : 'cursor-disabled'"
            >{{ $t('accountSetup') }}</span
          >
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 2 -->
      <v-stepper-step :complete="activeStep > 2" step="2" :disabled="!stepValidationResult[2]" @click="activeStep = 2">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-sm font-weight-semibold"
            :class="stepValidationResult[2] ? '' : 'cursor-disabled'"
            >{{ $t('personalProfileHeadingFinancial') }}</span
          >
          <span v-else :class="stepValidationResult[2] ? '' : ''">{{ $t('personalProfileHeadingFinancial') }}</span>
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-xs text--secondary"
            :class="stepValidationResult[2] ? 'text--secondary' : 'cursor-disabled'"
            >{{ $t('salaryDetails') }}</span
          >
        </div>
      </v-stepper-step>

      <v-divider></v-divider>
      <!-- Header: Step 3 -->
      <v-stepper-step :complete="activeStep > 3" step="3" :disabled="!stepValidationResult[3]" @click="activeStep = 3">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-sm font-weight-semibold"
            :class="stepValidationResult[3] ? '' : 'cursor-disabled'"
            >{{ $t('bavInformation') }}</span
          >
          <span v-else :class="stepValidationResult[3] ? '' : ''">{{ $t('bavInformation') }}</span>
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-xs text--secondary"
            :class="stepValidationResult[3] ? 'text--secondary' : 'cursor-disabled'"
            >{{ $t('bavSetup') }}</span
          >
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 4 -->
      <v-stepper-step step="4" :disabled="!stepValidationResult[4]" @click="activeStep = 4">
        <div class="d-flex flex-column text-center" :class="{ 'text-no-wrap': !$vuetify.breakpoint.smAndDown }">
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-sm font-weight-semibold"
            :class="stepValidationResult[4] ? '' : 'cursor-disabled'"
            >{{ $t('checkData') }}</span
          >
          <span v-else :class="stepValidationResult[4] ? '' : ''">{{ $t('checkData') }}</span>
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="text-xs text--secondary"
            :class="stepValidationResult[4] ? 'text--secondary' : 'cursor-disabled'"
            >{{ $t('summaryInformation') }}</span
          >
        </div>
      </v-stepper-step>
    </v-stepper-header>

    <!-- Stepper Items -->
    <div class="card-shadow">
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1" class="px-2 py-6">
          <d-personal-information-form
            v-if="userDetails"
            :personalData="userDetails.personalDetails"
            @stepForward="setStepValidationStatus(1, true)"
          />
        </v-stepper-content>
        <!-- Stepper Content: Step 2 -->
        <v-stepper-content step="2" class="px-2 py-6">
          <d-financial-information-form
            v-if="userDetails"
            :taxData="userDetails.taxDetails"
            @stepBack="activeStep -= 1"
            @stepForward="setStepValidationStatus(2, true)"
          />
        </v-stepper-content>
        <!-- Stepper Content: Step 3 -->
        <v-stepper-content step="3" class="px-2 py-6">
          <d-contribution-graphic v-if="!$vuetify.breakpoint.smAndDown"></d-contribution-graphic>
          <d-slider
            :contract="userContract"
            @stepBack="activeStep -= 1"
            @stepForward="setStepValidationStatus(3, true)"
          ></d-slider>
        </v-stepper-content>
        <!-- Stepper Content: Step 4 -->
        <v-stepper-content step="4" class="px-2 py-6">
          <d-combined-data-table
            v-if="userDetails"
            :contract="userContract"
            :personalData="userDetails.personalDetails"
            :taxData="userDetails.taxDetails"
            :isLoading="loadings.loading1"
            :isInStepper="true"
            @changeStep="activeStep = $event"
          ></d-combined-data-table>
          <v-row class="mb-5 px-4">
            <v-col cols="12" md="12" class="border-top pt-6 mt-6">
              <span class="check-data-heading pl-0">{{ $t('myBavCheckDataConfirm') }}</span>
              <v-checkbox
                v-if="$route.query.direct === 'true'"
                v-model="stepperConfirmCheckBox2"
                dense
                hide-details
                :label="$t('setupBavDirectlyConfirm')"
                :disabled="loadings.loading1"
              ></v-checkbox>
              <v-checkbox
                v-model="stepperConfirmCheckBox"
                dense
                hide-details
                :label="$t('myBavStepperConfirm')"
                :disabled="loadings.loading1"
              ></v-checkbox>
              <v-checkbox
                :key="currentLang"
                v-model="stepperConfirmCheckBoxAgbDpp"
                dense
                hide-details
                :disabled="loadings.loading1"
              >
                <template #label>
                  <span>{{ $t('acceptAgbDppStart') }}</span>
                  <a
                    href="./assets/DE_251_20230206_AGB_Portal_bei_Mitarbeiter_Angebotsanfrage.pdf"
                    target="_blank"
                    @click.stop
                  >
                    &nbsp;{{ $t('agb') }}&nbsp;
                  </a>
                  <span>{{ $t('acceptAgbDppAnd') }}</span>
                  <a href="./assets/datenschutzhinweise_degura_portal.pdf" target="_blank" @click.stop>
                    &nbsp;{{ $t('dpp') }}&nbsp;
                  </a>
                  <span>{{ $t('acceptAgbDppEnd') }}</span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <div
            v-if="$route.query.direct === 'true'"
            class="justify-space-between mt-5 px-4"
            :class="[
              { 'd-flex justify-space-between': !$vuetify.breakpoint.smAndDown },
              { 'd-flex flex-column justify-center': $vuetify.breakpoint.smAndDown },
            ]"
          >
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              color="primary"
              type="submit"
              class="request-offer__btn request-offer__btn--mobile mb-5"
              :disabled="!stepperConfirmCheckBox || !stepperConfirmCheckBox2 || !stepperConfirmCheckBoxAgbDpp"
              @click="submitContractData('request-contract')"
            >
              {{ $t('stepperButtonContinue4Direct') }}
            </v-btn>
            <v-btn
              outlined
              class="request-offer__btn--white"
              :class="[{ 'request-offer__btn request-offer__btn--mobile': $vuetify.breakpoint.smAndDown }]"
              @click="activeStep -= 1"
            >
              {{ $t('stepperButtonBack4') }}
            </v-btn>
            <v-btn
              v-if="!$vuetify.breakpoint.smAndDown"
              color="primary"
              type="submit"
              :disabled="!stepperConfirmCheckBox || !stepperConfirmCheckBox2 || !stepperConfirmCheckBoxAgbDpp"
              class="request-offer__btn"
              @click="submitContractData('request-contract')"
            >
              {{ $t('stepperButtonContinue4Direct') }}
            </v-btn>
          </div>
          <div
            v-else
            class="justify-space-between mt-5 px-4"
            :class="[
              { 'd-flex justify-space-between': !$vuetify.breakpoint.smAndDown },
              { 'd-flex flex-column justify-center': $vuetify.breakpoint.smAndDown },
            ]"
          >
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              color="primary"
              type="submit"
              class="request-offer__btn request-offer__btn--mobile mb-5"
              :disabled="!stepperConfirmCheckBox || !stepperConfirmCheckBoxAgbDpp"
              @click="submitContractData('request-offer')"
            >
              {{ $t('stepperButtonContinue4') }}
            </v-btn>
            <v-btn
              outlined
              class="request-offer__btn--white"
              :class="[{ 'request-offer__btn request-offer__btn--mobile': $vuetify.breakpoint.smAndDown }]"
              @click="activeStep -= 1"
            >
              {{ $t('stepperButtonBack4') }}
            </v-btn>
            <v-btn
              v-if="!$vuetify.breakpoint.smAndDown"
              color="primary"
              type="submit"
              :disabled="!stepperConfirmCheckBox || !stepperConfirmCheckBoxAgbDpp"
              class="request-offer__btn"
              @click="submitContractData('request-offer')"
            >
              {{ $t('stepperButtonContinue4') }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </div>
  </v-stepper>
</template>

<script>
import { onMounted, ref, getCurrentInstance, watch, computed } from '@vue/composition-api';
import { mdiCached, mdiCheckBold, mdiAlert } from '@mdi/js';
import { getEmployeeById, getContractByEmployeeId, requestContract, acceptTerms } from '@/api/employee';
import DPersonalInformationForm from './step-1/PersonalInformationForm.vue';
import DFinancialInformationForm from './step-2/FinancialInformationForm.vue';
import DContributionGraphic from './step-3/ContributionGraphic.vue';
import DSlider from './step-3/Slider.vue';
import DCombinedDataTable from '../employee-data/CombinedDataTable.vue';
import { BENEFICIARY_LEGAL_SUCCESSION } from '@/constants';

export default {
  components: {
    DPersonalInformationForm,
    DFinancialInformationForm,
    DContributionGraphic,
    DSlider,
    DCombinedDataTable,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { companyConfig } = vm.$store.state.auth;
    const { userData } = vm.$store.getters;
    const userDetails = ref(null);
    const userContract = ref(null);
    const stepperConfirmCheckBox = ref(false);
    const stepperConfirmCheckBox2 = ref(false);
    const stepperConfirmCheckBoxAgbDpp = ref(false);
    const activeStep = ref(1);
    const isDirectSetup = ref(false);
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const loadings = ref({
      loading1: false,
    });
    const triggerLoading = (loadingIndex) => {
      loadings.value[loadingIndex] = true;
    };
    const stepValidationResult = ref({
      1: true,
      2: false,
      3: false,
      4: false,
    });

    const setStepValidationStatus = (step, v) => {
      activeStep.value = step + 1;
      stepValidationResult.value[step] = v;
      stepValidationResult.value[step + 1] = v;
      if (step > 1) {
        stepValidationResult.value[step - 1] = v;
      }
    };

    const resetOnSteps = (step) => {
      stepValidationResult.value[step] = false;
    };

    watch(activeStep, (n, o) => {
      window.scrollTo(0, 100, { behavior: 'smooth' });
      if (n < o && o !== 4) {
        vm.$nextTick(() => resetOnSteps(o));
      }
    });

    const getDetails = (id) => {
      getEmployeeById(id).then((res) => {
        userDetails.value = res.data;
        userDetails.value.taxDetails = {
          ...userDetails.value.taxDetails,
          healthInsuranceContributionRate: Number(userDetails.value.taxDetails.healthInsuranceContributionRate) * 100,
        };

        getContractByEmployeeId(id)
          .then((res) => {
            userContract.value = res;
            vm.$store.commit('setContract', res);
          })
          .catch(() => {
            userContract.value = {
              beneficiaryDetails: {
                beneficiaryType: BENEFICIARY_LEGAL_SUCCESSION,
                beneficiaryFirstName: '',
                beneficiaryLastName: '',
              },
              employeeContribution: 40,
            };
          });
      });
    };

    const submitContractData = (type = 'request-offer') => {
      triggerLoading('loading1');

      acceptTerms();

      const payload = {
        beneficiaryDetails: {
          beneficiaryType: userContract.value.beneficiaryType,
        },
        personalDetails: userDetails.value.personalDetails,
        taxDetails: {
          ...userDetails.value.taxDetails,
          healthInsuranceContributionRate: Number(userDetails.value.taxDetails.healthInsuranceContributionRate) / 100,
        },
        totalContribution: userContract.value.totalContribution,
      };

      requestContract(userData.id, payload, type)
        .then(() => {
          loadings.value.loading1 = false;
          vm.$store.commit('showMessage', {
            text: (type = 'request-contract'
              ? 'successContractRequestNotification'
              : 'successOfferRequestNotification'),
            color: '#039181',
            timeout: '7000',
          });
          getDetails(userData.id);
        })
        .catch(() => {
          loadings.value.loading1 = false;
          vm.$store.commit('showMessage', {
            text: 'errorGenericTitle',
            color: 'error',
            timeout: '-1',
          });
        });
    };

    onMounted(() => {
      if (vm.$route.query?.direct) {
        isDirectSetup.value = vm.$route.query?.direct === 'true';
      } else {
        vm.$router.replace({ query: { direct: isDirectSetup.value } });
      }
      getDetails(userData.id);
    });

    watch(isDirectSetup, (v) => {
      vm.$router.replace({ query: { direct: v } });
    });

    return {
      currentLang,
      isDirectSetup,
      companyConfig,
      userDetails,
      userData,
      userContract,
      loadings,
      triggerLoading,
      stepValidationResult,
      setStepValidationStatus,
      activeStep,
      submitContractData,
      stepperConfirmCheckBoxAgbDpp,
      stepperConfirmCheckBox,
      stepperConfirmCheckBox2,

      // icon
      icons: { mdiCached, mdiCheckBold, mdiAlert },
    };
  },
};
</script>
<style lang="scss">
.v-stepper__label {
  color: #2830ce !important;
}
.v-stepper__header {
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.v-stepper__wrapper {
  padding: 10px;
}
.v-stepper__step {
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}

.cursor-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.v-stepper__header {
  .v-divider {
    border-color: rgba(40, 48, 206, 0.25) !important;
    padding: 1px;
    border-width: 2px !important;
  }
}

.request-offer {
  &__btn {
    background: #2830ce;
    border-radius: 3px;
    min-width: 300px !important;
    &--white {
      background: #ffffff;
      border: 1px solid #2830ce;
      border-radius: 3px;
      min-width: 300px !important;
      color: #2830ce !important;
    }
    &--mobile {
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
}

.offer-type {
  display: flex;
  align-items: center;
  /*height: 60px;*/
  width: 100%;
  background: #2830ce;
  border: 1px solid #2830ce;
  border-radius: 3px;
  margin-top: 25px;
  padding: 10px;

  .v-input--radio-group {
    margin: 0 !important;
  }

  div[role='radiogroup'] {
    label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
    }

    .v-icon {
      color: white !important;
    }
  }
}

@media only screen and (min-width: 959px) {
  .offer-type {
    height: 38px;
    justify-content: center;
    .v-input--radio-group {
      margin: 0 !important;
    }

    .v-radio {
      margin: 0 !important;
    }

    .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
      margin: 0 !important;
      margin-right: 80px !important;
    }

    div[role='radiogroup'] {
      flex-direction: row;
      color: white;
      margin: 0;
      margin-bottom: 5px;

      label {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #ffffff;
      }

      .v-icon {
        color: white !important;
      }
    }
  }
}
</style>
