<template>
  <div>
    <v-row justify="center" class="mb-1">
      <v-col cols="12" md="6" sm="12">
        <v-simple-table dense class="v-data-table--custom">
          <template v-slot:default>
            <thead>
              <span class="check-data-heading">
                {{ $t('personalProfileHeadingPersonal') }}
                <v-btn
                  v-if="isInStepper"
                  icon
                  color="info"
                  x-small
                  class="ml-2"
                  :disabled="isLoading"
                  @click="$emit('changeStep', 1)"
                >
                  <v-icon>
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </span>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('formLabelSalutation') }}</td>
                <td>
                  {{ $t(SALUTATION_TRANSLATION_MAP[personalData.salutation]) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelFirstName') }}</td>
                <td>
                  {{ personalData.firstName }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelLastName') }}</td>
                <td>
                  {{ personalData.lastName }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelDateOfBirth') }}</td>
                <td>
                  {{ moment(personalData.dateOfBirth).format('DD.MM.YYYY') }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelCountryOfBirth') }}</td>
                <td>
                  {{ personalData.countryOfBirth }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelStreetAddress') }}</td>
                <td>
                  {{ personalData.streetAddress }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelPostalCode') }}</td>
                <td>
                  {{ personalData.postalCode }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelCity') }}</td>
                <td>
                  {{ personalData.city }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelCountry') }}</td>
                <td>
                  {{ personalData.country }}
                </td>
              </tr>

              <tr>
                <td>{{ $t('formLabelPhone') }}</td>
                <td>
                  {{ personalData.phoneNumber }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelEmailPersonal') }}</td>
                <td>
                  {{ personalData.emailPersonal }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-simple-table dense class="v-data-table--custom">
          <template v-slot:default>
            <thead>
              <span class="check-data-heading">
                {{ $t('personalProfileHeadingFinancial') }}
                <v-btn
                  v-if="isInStepper"
                  icon
                  color="info"
                  x-small
                  class="ml-2"
                  :disabled="isLoading"
                  @click="$emit('changeStep', 2)"
                >
                  <v-icon>
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </span>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('formLabelNationality') }}</td>
                <td>
                  {{ taxData.nationality }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelProfession') }}</td>
                <td>
                  {{ taxData.occupation }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelTaxResidence') }}</td>
                <td>
                  {{ taxData.taxResidence }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelTaxClass') }}</td>
                <td>
                  {{ $t(TAX_CLASS_TRANSLATION_MAP[taxData.taxClass]) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelChildTaxAllowance') }}</td>
                <td>
                  {{ taxData.childAllowance }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelChurchTax') }}</td>
                <td>
                  {{ taxData.churchTax ? $t('yes') : $t('no') }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('formLabelHealthInsurance') }}</td>
                <td>
                  {{ $t(HEALTH_INSURANCE_TRANSLATION_MAP[taxData.healthInsuranceType]) }}
                </td>
              </tr>
              <tr v-if="taxData.healthInsuranceType !== HEALTH_INSURANCE_PRIVATE">
                <td>{{ $t('healthInsuranceProvider') }}</td>
                <td>
                  <!--{{taxData.healthInsuranceContributionRate}}-->
                  <span>
                    <strong>{{ taxData.healthInsuranceProvider }}</strong>
                    &mdash;{{ taxData.healthInsuranceContributionRate }}%
                  </span>
                </td>
              </tr>
              <template v-if="taxData.healthInsuranceType === HEALTH_INSURANCE_PRIVATE">
                <tr>
                  <td>{{ $t('formLabelTotalCont') }}</td>
                  <td>
                    {{ taxData.healthInsurancePrivateContribution }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('formLabelCareInsurance') }}</td>
                  <td>
                    {{ taxData.careInsuranceContribution }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t('formLabelTaxLevy') }}</td>
                  <td>
                    {{ taxData.incomeTaxContribution }}
                  </td>
                </tr>
              </template>
              <tr>
                <td>{{ $t('formLabelGrossWage') }}</td>
                <td>
                  {{ taxData.grossIncome }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('labelEmployeeStartDate') }}</td>
                <td v-if="taxData.employmentStartDate">
                  {{ moment(taxData.employmentStartDate).format('DD.MM.YYYY') }}
                </td>
              </tr>
            </tbody>
            <tbody v-if="contractData && isInStepper">
              <tr>
                <td>{{ $t('exampleCalcContributionLabel') }}</td>
                <td>{{ contractData.totalContribution }} €</td>
              </tr>
              <tr>
                <td>{{ $t('financialInformationBeneficiary') }}</td>
                <td>
                  {{ $t(BENEFICIARY_TRANSLATION_MAP[contractData.beneficiaryDetails.beneficiaryType]) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-1">
      <v-col cols="12" md="6" sm="12">
        <v-simple-table dense class="v-data-table--custom">
          <template v-slot:default>
            <thead>
              <span class="check-data-heading">
                {{ $t('bavInformation') }}
                <v-btn
                  v-if="isInStepper"
                  icon
                  color="info"
                  x-small
                  class="ml-2"
                  :disabled="isLoading"
                  @click="$emit('changeStep', 3)"
                >
                  <v-icon>
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </span>
            </thead>
            <tbody v-if="contract && isInStepper">
              <tr>
                <td>{{ $t('exampleCalcContributionLabel') }}</td>
                <td>€ {{ contract.totalContribution }}</td>
              </tr>
              <tr>
                <td style="width: 330px">
                  {{ $t('financialInformationBeneficiary') }}
                </td>
                <td>
                  {{ $t(BENEFICIARY_TRANSLATION_MAP[contract.beneficiaryType]) }}
                </td>
              </tr>
            </tbody>

            <tbody v-if="contractData && !isInStepper">
              <tr>
                <td>{{ $t('exampleCalcContributionLabel') }}</td>
                <td>{{ contractData.employeeContributionNet }} €</td>
              </tr>
              <tr>
                <td style="width: 310px">
                  {{ $t('financialInformationBeneficiary') }}
                </td>
                <td>
                  {{ $t(BENEFICIARY_TRANSLATION_MAP[contractData.beneficiaryDetails.beneficiaryType]) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiPencil } from '@mdi/js';
import moment from 'moment';
import {
  SALUTATION_TRANSLATION_MAP,
  HEALTH_INSURANCE_TRANSLATION_MAP,
  HEALTH_INSURANCE_PRIVATE,
  TAX_CLASS_TRANSLATION_MAP,
  BENEFICIARY_TRANSLATION_MAP,
} from '@/constants';

export default {
  setup() {
    return {
      SALUTATION_TRANSLATION_MAP,
      HEALTH_INSURANCE_TRANSLATION_MAP,
      HEALTH_INSURANCE_PRIVATE,
      TAX_CLASS_TRANSLATION_MAP,
      BENEFICIARY_TRANSLATION_MAP,
      moment,

      icons: {
        mdiPencil,
      },
    };
  },
  props: {
    isInStepper: {
      default: false,
    },
    isLoading: {
      default: false,
    },
    personalData: {
      type: Object,
      required: true,
    },
    contractData: {
      type: Object,
    },
    taxData: {
      type: Object,
    },
    contract: {
      type: Object,
    },
  },
};
</script>
<style lang="scss">
.v-data-table--custom {
  th {
    text-align: left;
    background-color: white !important;
    border-color: white !important;
  }
  tr td {
    border-color: white !important;
  }
}

.check-data-heading {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  text-align: left;
  color: #2830ce !important;
  margin-bottom: 23px;
  white-space: nowrap;
  padding-left: 16px;
}
</style>
