<template>
  <div v-if="config">
    <v-form ref="sliderForm" v-model="isFormValid" @submit.prevent="onSubmit">
      <p class="font-weight-bold">{{ $t('myBavNetContributionDescription') }}</p>

      <v-row>
        <v-col cols="12" sm="8" md="6">
          <v-text-field
            v-model="cpm"
            type="number"
            min="25"
            max="604"
            :rules="minMaxRules"
            dense
            outlined
            hide-details
            :label="$t('myBavNetContributionLabel')"
            placeholder="25 - 604"
            onwheel="this.blur()"
          ></v-text-field>
        </v-col>
      </v-row>

      <p class="font-weight-bold mt-8">{{ $t('myBavBeneficiaryDescription') }}</p>
      <v-row>
        <v-col cols="12" sm="8" md="6">
          <v-select
            v-if="contract"
            v-model="contract.beneficiaryType"
            :items="beneficiaryOptions"
            :rules="[validators.required]"
            :label="$t('financialInformationBeneficiary')"
            dense
            hide-details
            outlined
            class="select-custom my-1"
          ></v-select>
        </v-col>
      </v-row>

      <div
        class="justify-space-between mt-6"
        :class="[
          { 'd-flex justify-space-between': !$vuetify.breakpoint.smAndDown },
          { 'd-flex flex-column justify-center': $vuetify.breakpoint.smAndDown },
        ]"
      >
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          color="primary"
          :disabled="!isFormValid"
          type="submit"
          class="request-offer__btn mb-5"
          :class="[{ 'request-offer__btn request-offer__btn--mobile': $vuetify.breakpoint.smAndDown }]"
        >
          {{ $t('stepperButtonContinue3') }}
        </v-btn>
        <v-btn
          outlined
          class="request-offer__btn--white"
          :class="[{ 'request-offer__btn request-offer__btn--mobile': $vuetify.breakpoint.smAndDown }]"
          @click="$emit('stepBack')"
        >
          {{ $t('stepperButtonBack3') }}
        </v-btn>
        <v-btn
          v-if="!$vuetify.breakpoint.smAndDown"
          :disabled="!isFormValid"
          color="primary"
          type="submit"
          class="request-offer__btn"
        >
          {{ $t('stepperButtonContinue3') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref, toRefs } from '@vue/composition-api';
import { mdiMinus, mdiPause, mdiPlay, mdiPlus } from '@mdi/js';
import { numberRequired, required } from '../../../@core/utils/validation';
// eslint-disable-next-line object-curly-newline
import {
  BENEFICIARY_LEGAL_SUCCESSION,
  BENEFICIARY_PARTNER,
  BENEFICIARY_DEATH_GRANT,
  BENEFICIARY_NONE,
  BENEFICIARY_TRANSLATION_MAP,
} from '../../../constants';
import { config } from '../../../../appConfig';

export default {
  props: ['contract'],
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const cpm = ref('');
    const interval = ref(null);
    const isPlaying = ref(false);
    const isFormValid = ref(false);
    const sliderForm = ref(null);
    config.value = config;
    const { contract } = toRefs(props);

    const color = computed(() => {
      if (cpm.value < 100) return 'error';
      if (cpm.value < 125) return 'warning';
      if (cpm.value < 140) return 'success';
      if (cpm.value < 175) return 'success';

      return 'primary';
    });

    const decrement = () => {
      cpm.value -= 1;
    };

    const increment = () => {
      cpm.value += 1;
    };

    const onSubmit = () => {
      const isValid = sliderForm.value.validate();
      if (!isValid) return;

      contract.value.totalContribution = Number(cpm.value);
      vm.$emit('stepForward');
    };

    const beneficiaryOptions = computed(() => [
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_LEGAL_SUCCESSION]),
        value: BENEFICIARY_LEGAL_SUCCESSION,
      },
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_PARTNER]),
        value: BENEFICIARY_PARTNER,
      },
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_DEATH_GRANT]),
        value: BENEFICIARY_DEATH_GRANT,
      },
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_NONE]),
        value: BENEFICIARY_NONE,
      },
    ]);

    return {
      isFormValid,
      cpm,
      interval,
      isPlaying,
      color,
      beneficiaryOptions,
      decrement,
      increment,
      sliderForm,
      onSubmit,
      config,
      // icon
      icons: {
        mdiMinus,
        mdiPlus,
        mdiPause,
        mdiPlay,
      },
      validators: {
        required,
        numberRequired,
      },
      minMaxRules: [(v) => !!v || '', (v) => v >= 25 || '', (v) => v <= 604 || ''],
    };
  },
};
</script>
<style lang="scss">
.v-list--custom {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '. .'
    '. .';
  justify-content: center;
  .v-select {
    max-width: 200px;
  }
  .select-custom {
    top: -8px;
  }
}
</style>
