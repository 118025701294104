<template>
  <v-form ref="taxInfoForm" v-model="isFormValid" class="multi-col-validation mt-1" @submit.prevent="onSubmit">
    <v-row>
      <v-col md="6" cols="12">
        <v-row>
          <v-col class="mb-2" cols="12" md="12">
            <v-select
              id="nationality"
              v-model="taxData.nationality"
              :items="countries"
              :label="$t('formLabelNationality')"
              :rules="[validators.required]"
              dense
              outlined
              hide-details
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-select>
          </v-col>

          <v-col class="mb-2" md="12" cols="12">
            <v-text-field
              id="occupation"
              v-model="taxData.occupation"
              :label="$t('formLabelProfession')"
              :placeholder="$t('formLabelProfession')"
              :rules="[validators.required]"
              dense
              outlined
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </v-col>

          <v-col class="mb-2" md="12" cols="12">
            <v-select
              id="tax-residence"
              v-model="taxData.taxResidence"
              :items="countries"
              :label="$t('formLabelTaxResidence')"
              :rules="[validators.required]"
              dense
              outlined
              hide-details
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-select>
          </v-col>

          <v-col class="mb-2" md="12" cols="12">
            <v-badge top overlap content="1" class="badge-custom" color="info">
              <v-select
                id="tax-class-factor"
                v-model="taxData.taxClass"
                :items="taxClassOptions"
                :label="$t('formLabelTaxClass')"
                :placeholder="$t('formLabelTaxClass')"
                :rules="[validators.required]"
                dense
                outlined
                hide-details
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-select>
            </v-badge>
          </v-col>

          <v-col md="12" cols="12">
            <v-badge top overlap content="2" class="badge-custom" color="info">
              <v-select
                id="child-allowance"
                v-model="taxData.childAllowance"
                :items="childAllowanceOptions"
                :label="$t('formLabelChildTaxAllowance')"
                :placeholder="$t('formPlaceholderChildTaxAllowance')"
                :rules="[validators.numberRequired]"
                dense
                outlined
                hide-details
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-select>
            </v-badge>
          </v-col>

          <v-col md="12" cols="12">
            <v-radio-group v-model="taxData.churchTax" row hide-details>
              <template #label>
                <div class="label-custom mr-5">
                  <v-badge top overlap content="3" class="badge-custom" color="info">{{
                    $t('formLabelChurchTax')
                  }}</v-badge>
                </div>
              </template>
              <v-radio id="church-tax" :label="$t('yes')" :value="true"></v-radio>

              <v-radio :label="$t('no')" :value="false"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col class="mb-2" md="12" cols="12">
            <v-select
              id="insurance-typ"
              v-model="taxData.healthInsuranceType"
              :items="healthInsuranceOptions"
              :rules="[validators.required]"
              :label="$t('formLabelHealthInsurance')"
              :placeholder="$t('formLabelHealthInsurance')"
              dense
              outlined
              hide-details
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-select>

            <!--<v-radio-group-->
            <!--:rules="[validators.required]"-->
            <!--v-model="taxData.healthInsuranceType"-->
            <!--dense-->
            <!--outlined-->
            <!--hide-details-->
            <!--row-->
            <!--&gt;-->
            <!--<template #label>-->
            <!--<div class="label-custom label-custom&#45;&#45;2">{{$t('formLabelHealthInsurance')}}</div>-->
            <!--</template>-->
            <!--<v-radio-->
            <!--:items="items"-->
            <!--:label="$t('formLabelHealthInsurancePrivate')"-->
            <!--:placeholder="$t('formLabelHealthInsurancePrivate')"-->
            <!--value="radio-1"-->
            <!--id="insurance-type"-->
            <!--&gt;</v-radio>-->
            <!--<v-radio-->
            <!--:label="$t('formLabelHealthInsuranceStatutory')"-->
            <!--value="radio-2"-->
            <!--&gt;</v-radio>-->
            <!--</v-radio-group>-->
          </v-col>
          <v-col
            v-if="!fetchExistingProvider && taxData.healthInsuranceType !== HEALTH_INSURANCE_PRIVATE"
            class="mb-2"
            md="12"
            cols="12"
          >
            <v-badge top overlap content="4" class="badge-custom" color="info">
              <v-autocomplete
                v-model="taxData.healthInsuranceProvider"
                :items="healthInsuranceProviders"
                :label="$t('healthInsuranceProvider')"
                :placeholder="$t('healthInsuranceProvider')"
                :rules="[validators.required]"
                item-text="providerName"
                return-object
                dense
                outlined
                hide-details
                @change="handleHealthInsuranceProviderSelection"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
                <template v-slot:selection="data">
                  <template>
                    <span>
                      <strong>{{ taxData.healthInsuranceProvider }}</strong>
                      &mdash;{{ taxData.healthInsuranceContributionRate }}%
                    </span>
                  </template>
                </template>
                <template v-slot:item="data">
                  <template>
                    <span v-if="data">
                      <strong>{{ data.item.providerName }}</strong
                      >&mdash;{{ data.item.providerContribution }}%
                    </span>
                  </template>
                </template>
                <template slot="no-data">
                  <span class="px-2">{{ $t('myBavNoResults') }}</span>
                </template>
              </v-autocomplete></v-badge
            >
          </v-col>
          <!--<v-col-->
          <!--class="mb-2"-->
          <!--md="12"-->
          <!--cols="12"-->
          <!--v-if="taxData.healthInsuranceType !== HEALTH_INSURANCE_PRIVATE"-->
          <!--&gt;-->
          <!--<v-badge-->
          <!--top-->
          <!--overlap-->
          <!--content="4"-->
          <!--class="badge-custom"-->
          <!--color="info"-->
          <!--&gt;-->
          <!--<v-text-field-->
          <!--v-model="taxData.healthInsuranceContributionRate"-->
          <!--step="1"-->
          <!--max="100"-->
          <!--min="0"-->
          <!--type="number"-->
          <!--:rules="typeof taxData.healthInsuranceContributionRate === 'number'-->
          <!--? [validators.numberRequired, validators.percentageValidator]-->
          <!--: [validators.required, validators.percentageValidator]"-->

          <!--dense-->
          <!--outlined-->
          <!--:label="$t('formLabelContributionPerc')"-->
          <!--suffix="%"-->
          <!--id="contribution-rate"-->
          <!--&gt;</v-text-field>-->
          <!--</v-badge>-->
          <!--</v-col>-->

          <!--<v-col-->
          <!--md="12"-->
          <!--cols="12"-->
          <!--&gt;-->
          <!--<v-badge-->
          <!--top-->
          <!--overlap-->
          <!--content="4"-->
          <!--class="badge-custom"-->
          <!--color="info"-->
          <!--&gt;-->
          <!--<v-select-->
          <!--:items="countries"-->
          <!--:rules="[validators.required]"-->
          <!--label="Krankenkasse"-->
          <!--v-model="taxData.taxResidence"-->
          <!--dense-->
          <!--outlined-->
          <!--hide-details-->
          <!--id="tax-residence"-->
          <!--&gt;</v-select>-->

          <!--</v-badge>-->
          <!--</v-col>-->

          <template v-if="taxData.healthInsuranceType === HEALTH_INSURANCE_PRIVATE">
            <v-col class="mb-2" md="12" cols="12">
              <v-badge top overlap content="A" class="badge-custom" color="info">
                <v-text-field
                  id="contribution-private"
                  v-model="taxData.healthInsurancePrivateContribution"
                  dense
                  outlined
                  required
                  :rules="[validators.required]"
                  :label="$t('formLabelTotalCont')"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </v-badge>
            </v-col>

            <v-col class="mb-2" md="12" cols="12">
              <v-badge top overlap content="B" class="badge-custom" color="info">
                <v-text-field
                  id="care-insurance"
                  v-model="taxData.careInsuranceContribution"
                  :rules="[validators.required]"
                  dense
                  outlined
                  required
                  :label="$t('formLabelCareInsurance')"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </v-badge>
            </v-col>

            <v-col class="mb-2" md="12" cols="12">
              <v-badge top overlap content="C" class="badge-custom" color="info">
                <v-text-field
                  id="income-tax"
                  v-model="taxData.incomeTaxContribution"
                  dense
                  outlined
                  required
                  :rules="[validators.required]"
                  :label="$t('formLabelTaxLevy')"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </v-badge>
            </v-col>
          </template>
          <v-col md="12" cols="12">
            <v-badge top overlap content="5" class="badge-custom" color="info">
              <v-text-field
                id="gross-income"
                v-model="taxData.grossIncome"
                type="number"
                :rules="typeof taxData.grossIncome === 'number' ? [validators.numberRequired] : [validators.required]"
                dense
                outlined
                onwheel="this.blur()"
                :label="$t('formLabelGrossWage')"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-badge>
          </v-col>
          <v-col cols="12">
            <v-menu
              ref="menuref"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge top overlap content="6" class="badge-custom" color="info">
                  <v-text-field
                    v-bind="attrs"
                    id="employment-start-date"
                    :label="$t('labelEmployeeStartDate')"
                    readonly
                    outlined
                    dense
                    :prepend-inner-icon="icons.mdiCalendar"
                    :value="
                      taxData.employmentStartDate ? moment(taxData.employmentStartDate).format('DD.MM.YYYY') : null
                    "
                    :rules="[validators.required]"
                    v-on="on"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field></v-badge
                >
              </template>

              <v-date-picker
                ref="picker"
                v-model="taxData.employmentStartDate"
                min="1950-01-01"
                color="primary"
                @change="save"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.smAndDown" md="6" cols="6">
        <v-card class="mb-8 ml-5" outlined>
          <v-img src="@/assets/images/request-offer/example2.svg" alt="logo" max-height="611"> </v-img>
        </v-card>
      </v-col>
    </v-row>
    <div
      class="mt-5"
      :class="[
        { 'd-flex justify-space-between': !$vuetify.breakpoint.smAndDown },
        { 'd-flex flex-column justify-center': $vuetify.breakpoint.smAndDown },
      ]"
    >
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        color="primary"
        type="submit"
        :disabled="!isFormValid"
        :class="[
          {
            'request-offer__btn request-offer__btn--mobile': $vuetify.breakpoint.smAndDown,
          },
        ]"
        class="request-offer__btn mb-5"
      >
        {{ $t('stepperButtonContinue2') }}
      </v-btn>
      <v-btn
        outlined
        class="request-offer__btn--white"
        :class="[
          {
            'request-offer__btn request-offer__btn--mobile': $vuetify.breakpoint.smAndDown,
          },
        ]"
        @click="$emit('stepBack')"
      >
        {{ $t('stepperButtonBack2') }}
      </v-btn>
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        :disabled="!isFormValid"
        color="primary"
        type="submit"
        class="request-offer__btn"
      >
        {{ $t('stepperButtonContinue2') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { ref, getCurrentInstance, toRefs, computed } from '@vue/composition-api';
import { mdiCalendar } from '@mdi/js';
import moment from 'moment';
import {
  HEALTH_INSURANCE_TRANSLATION_MAP,
  HEALTH_INSURANCE_PRIVATE,
  HEALTH_INSURANCE_STATUTORY,
  TAX_CLASS_TRANSLATION_MAP,
  TAX_CLASS_1,
  TAX_CLASS_2,
  TAX_CLASS_3,
  TAX_CLASS_4,
  TAX_CLASS_5,
  TAX_CLASS_6,
  createCountrySelection,
} from '../../../constants';
import { updateEmployeeTaxData } from '../../../api/employee';
import { getHealthInsuranceProviders, getHealthInsuranceProviderById } from '../../../api/health-insurance';
import { required, numberRequired, percentageValidator } from '../../../@core/utils/validation';

export default {
  components: {},

  props: {
    taxData: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const { userData } = vm.$store.getters;
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const countries = computed(() => createCountrySelection(currentLang.value === 'en' ? 'en' : 'de'));
    const taxInfoForm = ref(null);
    const fetchExistingProvider = ref(false);
    const isFormValid = ref(false);
    const healthInsuranceProviders = ref([]);
    const date = ref(null);
    const menu = ref(false);
    const picker = ref(null);
    const menuref = ref(null);
    const healthInsuranceOptions = computed(() => [
      {
        text: vm.$t(HEALTH_INSURANCE_TRANSLATION_MAP[HEALTH_INSURANCE_STATUTORY]),
        value: HEALTH_INSURANCE_STATUTORY,
      },
      {
        text: vm.$t(HEALTH_INSURANCE_TRANSLATION_MAP[HEALTH_INSURANCE_PRIVATE]),
        value: HEALTH_INSURANCE_PRIVATE,
      },
    ]);

    const childAllowanceOptions = [
      { value: 0, text: '0' },
      { value: 0.5, text: '0.5' },
      { value: 1, text: '1' },
      { value: 1.5, text: '1.5' },
      { value: 2, text: '2' },
      { value: 2.5, text: '2.5' },
      { value: 3, text: '3' },
      { value: 3.5, text: '3.5' },
      { value: 4, text: '4' },
      { value: 4.5, text: '4.5' },
      { value: 5, text: '5' },
    ];

    const taxClassOptions = [
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_1]),
        value: TAX_CLASS_1,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_2]),
        value: TAX_CLASS_2,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_3]),
        value: TAX_CLASS_3,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_4]),
        value: TAX_CLASS_4,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_5]),
        value: TAX_CLASS_5,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_6]),
        value: TAX_CLASS_6,
      },
    ];

    const { taxData } = toRefs(props);

    const onSubmit = () => {
      const isValid = taxInfoForm.value.validate();
      if (!isValid) return;

      const payload = {
        ...taxData.value,
        healthInsuranceContributionRate: Number(taxData.value.healthInsuranceContributionRate) / 100,
      };

      updateEmployeeTaxData(userData.id, payload).then(() => {
        vm.$emit('stepForward');
      });
    };

    const handleHealthInsuranceProviderSelection = (provider) => {
      taxData.value.healthInsuranceId = provider.id;
      taxData.value.healthInsuranceProvider = provider.providerName;
      taxData.value.healthInsuranceContributionRate = Number(provider.providerContribution);
    };

    const fetchInsuranceProviders = () => {
      getHealthInsuranceProviders(0, 1000 /* Do we need pagination here? */).then((res) => {
        healthInsuranceProviders.value = res.data.page;
        if (taxData.value.healthInsuranceId) {
          fetchExistingProvider.value = true;
          getHealthInsuranceProviderById(taxData.value.healthInsuranceId).then((res) => {
            taxData.value.healthInsuranceProvider = res.data.providerName;
            taxData.value.healthInsuranceContributionRate = res.data.providerContribution;
            fetchExistingProvider.value = false;
          });
        }
      });
    };

    const save = (d) => {
      menuref.value.save(d);
    };
    fetchInsuranceProviders();

    return {
      fetchExistingProvider,
      healthInsuranceOptions,
      childAllowanceOptions,
      taxClassOptions,
      countries,
      taxInfoForm,
      isFormValid,
      HEALTH_INSURANCE_PRIVATE,
      healthInsuranceProviders,
      onSubmit,
      handleHealthInsuranceProviderSelection,
      moment,
      date,
      menu,
      menuref,
      picker,
      save,

      validators: {
        required,
        numberRequired,
        percentageValidator,
      },
      icons: {
        mdiCalendar,
      },
    };
  },
};
</script>
<style lang="scss">
.badge-custom {
  width: 100%;
}
.label-custom {
  position: absolute;
  top: -34px;
  left: 10px;
  width: auto;
  min-width: 75px;
  &--2 {
    min-width: 120px;
  }
  .info {
    margin-left: 15px;
  }
}

@media screen and (max-width: 960px) {
  .v-badge__badge {
    display: none !important;
  }
}
</style>
