<template>
  <div>
    <div class="d-flex align-items-center border-bottom mb-10">
      <p class="contribution-description">
        {{ $t('noticeContributionExample') }}
      </p>
      <v-img
        :src="imgPath('degura_graphic_recommended_contribution')"
        alt="contribution"
        max-height="315"
        contain
        class="mb-10 mt-2"
      >
      </v-img>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, computed } from '@vue/composition-api';

export default {
  name: 'ContributionGraphic',
  setup() {
    const vm = getCurrentInstance().proxy;

    const currentLang = computed(() => vm.$store.state.app.currentLang);

    const imgPath = (filename) => {
      return require(`@/assets/images/request-offer/${filename}${currentLang.value === 'en' ? '_EN' : ''}.svg`);
    };

    return {
      imgPath,
      currentLang,
    };
  },
};
</script>
<style>
.contribution-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  max-width: 367px;
  margin-right: 32px;
}
</style>
