<template>
  <div class="pt-7 shared-documents">
    <div class="pb-5">
      <p class="mb-5">{{ $t('myBavDocumentsDescription') }}</p>
      <div class="shared-documents__table mb-5">
        <v-card class="shared-documents__table-card" :class="{ 'v-card--no-border': $vuetify.breakpoint.smAndDown }">
          <v-data-table
            :key="rerenderKey"
            v-model="selectedDocs"
            :headers="headers"
            :items="loadedDocuments"
            :loading="loading"
            :mobile-breakpoint="0"
            :hide-default-footer="true"
            :sort-by.sync="sortingOptions"
            :sort-desc.sync="sortingIsDesc"
            class="text-no-wrap cursor-pointer"
            :item-class="setRowClass"
            @click:row="(row) => download(row.id)"
          >
            <template #[`header.trending`]>
              <v-icon size="22">
                {{ icons.mdiTrendingUp }}
              </v-icon>
            </template>

            <template #[`item.name`]="{ item }">
              <span class="text-no-wrap th" :title="item.name" @click:row="(row) => download(row.id)">{{
                item.name
              }}</span>
            </template>

            <template #[`item.category`]="{ item }">
              <span>{{ getDocumentCategoryTranslation(item.category) }}</span>
            </template>

            <template #[`item.employeeOnly`]="{ item }">
              <div class="d-flex align-center">
                <div class="d-flex flex-column">
                  <span v-if="item.employeeOnly">
                    {{ $t('myBavPersonalAdvisor') }}
                  </span>
                  <span v-else>{{ $t('toggleLableShareWithHR') }}</span>
                </div>
              </div>
            </template>

            <template #[`item.createdAt`]="{ item }">
              <span class="text-xs text-no-wrap now d-none">{{ $t('uploadDocumentNow') }}</span>
              <span class="text-xs text-no-wrap">
                {{ moment.utc(item.createdAt).local().format('DD.MM.YYYY') }} /
                {{ moment.utc(item.createdAt).local().format('HH:mm') }} Uhr
              </span>
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{ item }">
              <div class="d-flex justify-center">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      size="16"
                      class="mr-6"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="download(item.id)"
                    >
                      {{ icons.mdiDownload }}
                    </v-icon>
                  </template>
                  <span>{{ $t('download') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template slot="no-data">
              <div class="my-5">
                <p>{{ $t('myBavNoResults') }}</p>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <!-- edit  -->
    <ModalWindow :is-open="editDocumentsModal" :title="$t('myBavEditModalTitle')" :stacked="true" :maxWidth="400">
      <template #content>
        <div v-if="documentToEdit">
          <v-text-field
            v-model="documentToEdit.name"
            :label="$t('myBavUploadModalDocLabel')"
            :placeholder="$t('myBavUploadModalDocLabel')"
            dense
            readonly
            outlined
          ></v-text-field>
          <v-select
            v-model="documentToEdit.category"
            :items="uploadCategoryOptions"
            :label="$t('myBavUploadModalCategoryLabel')"
            :placeholder="$t('myBavUploadModalCategoryLabel')"
            dense
            outlined
          ></v-select>
          <p class="font-weight-bold text-left">
            {{ $t('tableHeaderSharedWith') }}
          </p>
          <div class="text-left">
            <div class="d-flex mt-2 flex-1">
              <div class="mr-3">{{ $t('myBavUploadModalShare') }}</div>
              <v-switch v-model="documentToEdit.sharedWithHr" class="mt-0 pt-0" dense hide-details></v-switch>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <v-btn block color="primary" @click="confirmEdit">
          {{ $t('buttonConfirm') }}
        </v-btn>
        <v-btn outlined block color="primary" class="ml-0 mt-3" @click="editDocumentsModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
      </template>
    </ModalWindow>

    <!-- archive  -->

    <ModalWindow :is-open="archiveDocumentsModal" warning>
      <template #content>
        <p v-if="selectedDocs.length > 0" class="text-base">
          {{ $t('myBavRecycleBinConfirmationMultiple') }}
        </p>
        <p v-else class="text-base">{{ $t('myBavRecycleBinConfirmation') }}</p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="archiveDocumentsModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirmArchive">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>

    <!-- delete  -->

    <ModalWindow :is-open="deleteDocumentsModal" error>
      <template #content>
        <p v-if="selectedDocs.length > 0" class="text-base">
          {{ $t('myBavPermanentDeletionConfirmationMultiple') }}
        </p>
        <p v-else class="text-base">{{ $t('myBavPermanentDeletionConfirmation') }}</p></template
      >
      <template #actions>
        <v-btn outlined color="primary" @click="deleteDocumentsModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirmDelete">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencil,
  mdiDownload,
  mdiInformationOutline,
  mdiMagnify,
  mdiInformation,
} from '@mdi/js';

import { required } from '@core/utils/validation';
import moment from 'moment';
import { debounce } from 'lodash';
import { ref, getCurrentInstance, onMounted, computed, watch } from '@vue/composition-api';
import {
  DOC_CUSTOMER_INFO,
  DOC_OTHER,
  STATUS_ACTIVE,
  STATUS_DELETED,
  EMPLOYEE_CATEGORIES,
  CATEGORY_TRANSLATION_MAP,
  DOCUMENT_TYPE_ICON_MAP,
  HR_ROLES,
  ROLE_ORG_ADMIN,
  ROLE_KEY_ACCOUNT,
  DOCUMENT_SECURITY_SCAN_STATUS_TRANSLATION_MAP,
  ROLE_BAV_MANAGER,
  DOC_SECURITY_SCAN_CLEAN,
  DOC_SECURITY_SCAN_INFECTED,
} from '@/constants';

import {
  uploadDocument,
  categorizeDocument,
  downloadDocument,
  archiveDocument,
  deleteDocument,
} from '../../../../api/document';
import {
  GRANT_DOCUMENT_ACCESS_TO_HR_ACTION,
  REVOKE_DOCUMENT_ACCESS_TO_HR_ACTION,
} from '../../../../store/modules/documents/actions';
import DragNDropUpload from '@/components/upload/DragNDropUpload';
import ModalWindow from '@/components/modal/ModalWindow';

export default {
  name: 'MeineDocuments',
  components: { DragNDropUpload, ModalWindow },
  setup() {
    const vm = getCurrentInstance().proxy;
    const currentPage = ref(1);
    const rerenderKey = ref(0);

    const statusOptions = ref([
      { value: STATUS_ACTIVE, text: vm.$t('myBavActive') },
      { value: STATUS_DELETED, text: vm.$t('myBavRecycleBin') },
    ]);
    const sortingOptions = ref(['createdAt']);
    const statusFilter = ref(STATUS_ACTIVE);
    const categoryFilter = ref('');
    const searchQuery = ref('');

    const documentsToUpload = ref([]);
    const selectedDocs = ref([]);
    const documentToDelete = ref(null);
    const documentToEdit = ref(null);

    const sortingIsDesc = ref(true);
    const grantHrAccessWithUpload = ref(false);
    const uploadDocumentsModal = ref(false);
    const editDocumentsModal = ref(false);
    const archiveDocumentsModal = ref(false);
    const deleteDocumentsModal = ref(false);
    const employee = ref(vm.$store.getters.userData.id);
    const setRowClass = (item) => {
      for (const document of vm.$store.getters['upload/uploadedData']) {
        if (item.id === document.id) {
          return 'shared-documents--new-row';
        }
      }
    };
    const categoryOptions = computed(() => {
      return EMPLOYEE_CATEGORIES.map((e) => ({
        text: vm.$t(CATEGORY_TRANSLATION_MAP[e]),
        value: e,
      }));
    });

    const uploadCategoryOptions = computed(() => {
      return categoryOptions.value.filter((i) => i.value !== DOC_CUSTOMER_INFO);
    });

    const loadedDocuments = computed(() => vm.$store.getters['documents/DOCUMENTS_PAGE']);
    const totalLoadedDocuments = computed(() => vm.$store.state.documents.total);
    const loading = computed(() => vm.$store.state.documents.loading);
    const { activeRole } = vm.$store.getters;

    const headers = computed(() => {
      return [
        {
          text: vm.$t('tableHeaderDocuments'),
          value: 'name',
          sortable: false,
          class: 'text-uppercase',
          width: '25%',
        },
        {
          text: vm.$t('tableHeaderCategory'),
          value: 'category',
          sortable: false,
          class: 'text-uppercase shared-documents__table-header',
          width: '10%',
        },
        {
          text: vm.$t('tableHeaderSharedWith'),
          value: 'employeeOnly',
          sortable: false,
          class: 'text-uppercase shared-documents__table-header',
          width: '15%',
        },
        {
          text: vm.$t('tableHeaderTimeDate'),
          value: 'createdAt',
          sortable: false,
          class: 'text-uppercase shared-documents__table-header',
          width: '15%',
        },
        {
          text: vm.$t('myBavActions'),
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'text-uppercase shared-documents__table-header',
          width: '10.5%',
        },
      ];
    });

    const fetchDocuments = () => {
      vm.$store.dispatch('documents/fetchAll');
    };

    const onFileListChange = (fileList) => {
      uploadDocumentsModal.value = true;
      createUploadObjects(fileList);
    };

    const createUploadObjects = (files) => {
      documentsToUpload.value = [];
      grantHrAccessWithUpload.value = false;

      for (let i = 0; i < files.length; i++) {
        documentsToUpload.value[i] = {
          fileIndex: i,
          cancelled: false,
          category: DOC_OTHER,
          categorized: false,
          id: null,
          loaded: 0,
          failureReason: null,
          source: files[i],
        };
      }
    };

    const confirmUpload = () => {
      documentsToUpload.value.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file.source);

        const res = await uploadDocument(vm.$store.getters.userData.id, formData, activeRole);
        await categorizeDocument(res.data.id, file.category);

        if (grantHrAccessWithUpload.value) {
          vm.$store.dispatch(`documents/${GRANT_DOCUMENT_ACCESS_TO_HR_ACTION}`, res.data.id);
        }
        await fetchDocuments();
      });

      uploadDocumentsModal.value = false;
    };

    const confirmEdit = () => {
      categorizeDocument(documentToEdit.value.id, documentToEdit.value.category).then(() => {
        if (documentToEdit.value.sharedWithHr) {
          vm.$store.dispatch(`documents/${GRANT_DOCUMENT_ACCESS_TO_HR_ACTION}`, documentToEdit.value.id);
        } else {
          vm.$store.dispatch(`documents/${REVOKE_DOCUMENT_ACCESS_TO_HR_ACTION}`, documentToEdit.value.id);
        }

        documentToEdit.value = null;
        editDocumentsModal.value = false;

        fetchDocuments();
      });
    };

    const download = (id) => {
      downloadDocument(id);
    };

    const searchData = debounce(function () {
      vm.$store.dispatch('documents/search', searchQuery.value);
    }, 300);

    const getSecurityStatusTranslation = (scanStatus) => {
      return vm.$t(DOCUMENT_SECURITY_SCAN_STATUS_TRANSLATION_MAP[scanStatus]);
    };

    const setStatus = () => {
      vm.$store.dispatch('documents/setStatus', statusFilter.value);
    };

    const setCategory = () => {
      vm.$store.dispatch('documents/filterCategory', categoryFilter.value);
    };

    const setSorting = (fieldName, direction) => {
      vm.$store.commit('documents/CHANGE_SORT_DIRECTION', direction);
      vm.$store.dispatch('documents/sort', fieldName);
    };

    const setLimit = (limit) => {
      vm.$store.dispatch('documents/setLimit', limit);
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) onNextPage();
      if (currentPage.value > event.page) onPreviousPage();

      currentPage.value = event.page;
    };

    const onPreviousPage = () => {
      vm.$store.dispatch('documents/previousPage');
    };

    const onNextPage = () => {
      vm.$store.dispatch('documents/nextPage');
    };

    const onDelete = (id) => {
      if (statusFilter.value === STATUS_DELETED) {
        deleteDocumentsModal.value = true;
      } else {
        archiveDocumentsModal.value = true;
      }

      if (id) documentToDelete.value = id;
    };

    const onEdit = (doc) => {
      editDocumentsModal.value = true;
      documentToEdit.value = {
        id: doc.id,
        name: doc.name,
        category: doc.category,
        sharedWithAm: doc.permittedRoles.includes(ROLE_KEY_ACCOUNT),
        sharedWithHr: doc.permittedRoles.includes(ROLE_BAV_MANAGER),
      };
    };

    const confirmArchive = () => {
      if (selectedDocs.value.length > 0) {
        return confirmMultipleArchive();
      }
      return archiveDocument(documentToDelete.value).then(() => {
        archiveDocumentsModal.value = false;
        documentToDelete.value = false;

        fetchDocuments();
      });
    };

    const confirmMultipleArchive = () => {
      const promiseArr = [];

      const docs = selectedDocs.value.filter((i) => i.category !== DOC_CUSTOMER_INFO);
      docs.map(async (i) => {
        promiseArr.push(archiveDocument(i.id));
      });

      Promise.all(promiseArr).then(() => {
        archiveDocumentsModal.value = false;
        documentToDelete.value = false;
        selectedDocs.value = [];

        fetchDocuments();
      });
    };

    const confirmDelete = () => {
      if (selectedDocs.value.length > 0) {
        return confirmMultipleDelete();
      }
      return deleteDocument(documentToDelete.value).then(() => {
        deleteDocumentsModal.value = false;
        documentToDelete.value = false;

        fetchDocuments();
      });
    };

    const confirmMultipleDelete = () => {
      const promiseArr = [];

      selectedDocs.value.map(async (i) => {
        promiseArr.push(deleteDocument(i.id));
      });

      Promise.all(promiseArr).then(() => {
        deleteDocumentsModal.value = false;
        documentToDelete.value = false;
        selectedDocs.value = [];

        fetchDocuments();
      });
    };

    const getDocumentCategoryTranslation = (category) => {
      return vm.$t(CATEGORY_TRANSLATION_MAP[category]);
    };

    onMounted(() => {
      vm.$store.commit('documents/SET_TYPE', 'EMPLOYEE');

      // employee only
      vm.$store.commit('documents/SET_EMPLOYEE', vm.$store.getters.userData.id);

      setLimit(15);
    });

    watch(sortingIsDesc, () => {
      if (sortingOptions.value.length > 0) {
        const direction = sortingIsDesc.value ? 'DESC' : 'ASC';
        setSorting(sortingOptions.value[0], direction);
      }
    });

    watch(documentsToUpload, (v) => {
      if (v.length === 0 && uploadDocumentsModal.value) {
        uploadDocumentsModal.value = false;
      }
    });

    watch(
      () => vm.$store.state.app.currentLang,
      () => {
        statusOptions.value = [
          { value: STATUS_ACTIVE, text: vm.$t('myBavActive') },
          { value: STATUS_DELETED, text: vm.$t('myBavRecycleBin') },
        ];
        rerenderKey.value += 1;
        vm.$forceUpdate();
      },
    );

    return {
      rerenderKey,
      currentPage,
      headers,
      loadedDocuments,
      searchQuery,
      loading,
      totalLoadedDocuments,
      statusFilter,
      getSecurityStatusTranslation,
      getDocumentCategoryTranslation,
      moment,
      categoryOptions,
      uploadCategoryOptions,
      statusOptions,
      categoryFilter,
      searchData,
      sortingOptions,
      sortingIsDesc,
      uploadDocumentsModal,
      archiveDocumentsModal,
      deleteDocumentsModal,
      editDocumentsModal,
      setCategory,
      setStatus,
      setLimit,
      handlePagination,
      onFileListChange,
      onDelete,
      onEdit,
      documentsToUpload,
      documentToEdit,
      confirmUpload,
      confirmArchive,
      confirmDelete,
      activeRole,
      selectedDocs,
      download,
      confirmEdit,
      grantHrAccessWithUpload,
      ROLE_BAV_MANAGER,
      DOC_CUSTOMER_INFO,
      fetchDocuments,
      employee,
      setRowClass,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownload,
        mdiPencil,
        mdiInformationOutline,
        mdiMagnify,
        mdiInformation,
        mdiDelete,
      },

      validators: {
        required,
      },
    };
  },
};
</script>

<style lang="scss"></style>
