<template>
  <div>
    <v-form ref="personalInfoForm" v-model="isFormValid" class="multi-col-validation" @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12" md="12">
          <div class="radio-label">{{ $t('formLabelSalutation') }}</div>
          <v-radio-group id="radio-mr" v-model="personalData.salutation" row hide-details class="mt-0">
            <v-radio :label="$t('formLabelSalutationMale')" :value="SALUTATION_MR"></v-radio>
            <v-radio :label="$t('formLabelSalutationFemale')" :value="SALUTATION_MRS"></v-radio>
            <v-radio :label="$t('formLabelSalutationDiverse')" :value="SALUTATION_DIV"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="firstname"
            v-model="personalData.firstName"
            :rules="[validators.required]"
            :label="$t('formLabelFirstName')"
            :placeholder="$t('formLabelFirstName')"
            dense
            outlined
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template></v-text-field
          >
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="lastname"
            v-model="personalData.lastName"
            :rules="[validators.required]"
            :label="$t('formLabelLastName')"
            dense
            outlined
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-menu
            ref="menuref"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                id="dob"
                :value="moment(personalData.dateOfBirth).format('DD.MM.YYYY')"
                :rules="[validators.required, validators.ageValidator]"
                :label="$t('formLabelDateOfBirth')"
                readonly
                outlined
                dense
                :prepend-inner-icon="icons.mdiCalendar"
                v-on="on"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </template>

            <v-date-picker
              ref="picker"
              v-model="personalData.dateOfBirth"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              color="primary"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="city-of-birth"
            v-model="personalData.cityOfBirth"
            dense
            outlined
            :label="$t('formLabelPlaceOfBirth')"
            :rules="[validators.required]"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-select
            id="country-birth"
            v-model="personalData.countryOfBirth"
            :label="$t('formLabelCountryOfBirth')"
            :items="countries"
            :rules="[validators.required]"
            dense
            outlined
            hide-details
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-select>
        </v-col>
        <v-col md="6" cols="12"></v-col>
        <v-col md="6" cols="12">
          <v-text-field
            id="street-address"
            v-model="personalData.streetAddress"
            dense
            outlined
            :label="$t('formLabelStreetAddress')"
            :rules="[validators.required]"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="postal-code"
            v-model="personalData.postalCode"
            dense
            outlined
            :label="$t('formLabelPostalCode')"
            :rules="[validators.required]"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field
            id="city"
            v-model="personalData.city"
            dense
            outlined
            :label="$t('formLabelCity')"
            :rules="[validators.required]"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-select
            v-model="personalData.country"
            :rules="[validators.required]"
            :items="countries"
            :label="$t('formLabelCountry')"
            dense
            outlined
            hide-details
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-select>
        </v-col>

        <v-col md="6" cols="12">
          <!--<v-text-field-->
          <!--v-model="personalData.phoneNumber"-->
          <!--:rules="[validators.phoneNumberValidator, validators.required]"-->
          <!--dense-->
          <!--outlined-->
          <!--:label="$t('formLabelPhone')"-->
          <!--id="phone"-->
          <!--:prepend-inner-icon="icons.mdiCellphone"-->
          <!--&gt;</v-text-field>-->
          <vue-tel-input-vuetify
            id="phone"
            v-model="personalData.phoneNumber"
            validate-on-blur
            :label="$t('formLabelPhone')"
            defaultCountry="DE"
            mode="national"
            dense
            disabledFetchingCountry
            :onlyCountries="['DE']"
            :rules="[validators.required, validators.localPhoneNumberValidator]"
            :valid-characters-only="true"
            :inputOptions="{ showDialCode: false }"
            outlined
            class="align-start"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </vue-tel-input-vuetify>
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            id="email-personal"
            v-model="personalData.emailPersonal"
            dense
            outlined
            :label="$t('formLabelEmailPersonal')"
            :prepend-inner-icon="icons.mdiEmailOutline"
            :rules="[validators.required, validators.emailValidator]"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div
        class="mt-5"
        :class="[
          { 'd-flex justify-end': !$vuetify.breakpoint.smAndDown },
          { 'd-flex justify-center': $vuetify.breakpoint.smAndDown },
        ]"
      >
        <v-btn
          :disabled="!isFormValid"
          color="primary"
          :class="[
            {
              'request-offer__btn request-offer__btn--mobile': $vuetify.breakpoint.smAndDown,
            },
          ]"
          type="submit"
          class="request-offer__btn"
        >
          {{ $t('stepperButtonContinue1') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref, watch, getCurrentInstance, toRefs, computed, onMounted } from '@vue/composition-api';
import { mdiCalendar, mdiEmailOutline, mdiCellphone } from '@mdi/js';
import {
  required,
  emailValidator,
  phoneNumberValidator,
  localPhoneNumberValidator,
  ageValidator,
} from '@core/utils/validation';
import moment from 'moment';
import { updateEmployeePersonalData } from '../../../api/employee';
import { createCountrySelection, SALUTATION_MR, SALUTATION_DIV, SALUTATION_MRS } from '../../../constants';

export default {
  components: {},
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const { userData } = vm.$store.getters;
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const date = ref(null);
    const menu = ref(false);
    const picker = ref(null);
    const menuref = ref(null);
    const personalInfoForm = ref(null);
    const isFormValid = ref(false);
    const items = ['Mr', 'Mrs', 'Diverse'];
    const countries = computed(() => createCountrySelection(currentLang.value === 'en' ? 'en' : 'de'));

    const { personalData } = toRefs(props);

    watch(menu, (val) => {
      // eslint-disable-next-line no-return-assign
      val && setTimeout(() => (picker.value.activePicker = 'YEAR'));
    });

    const save = (d) => {
      menuref.value.save(d);
    };

    const onSubmit = () => {
      const isValid = personalInfoForm.value.validate();
      if (!isValid) return;

      updateEmployeePersonalData(userData.id, personalData.value).then(() => {
        vm.$emit('stepForward');
      });
    };

    return {
      moment,
      date,
      menu,
      picker,
      menuref,
      save,
      items,
      personalInfoForm,
      isFormValid,
      onSubmit,
      countries,
      SALUTATION_MR,
      SALUTATION_MRS,
      SALUTATION_DIV,

      // icons
      icons: {
        mdiCalendar,
        mdiEmailOutline,
        mdiCellphone,
      },
      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
        localPhoneNumberValidator,
        ageValidator,
      },
    };
  },
  props: {
    personalData: {
      type: Object,
      required: false,
    },
    companyData: {
      type: Object,
    },
  },
};
</script>
<style>
.radio-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  color: #505a68;
}
.vue-tel-input-vuetify .v-input--has-state {
  transform: translate(0);
}
</style>
