<template>
  <v-row>
    <v-col cols="12" sm="6" md="6" lg="6" class="d-flex flex-column">
      <div class="large-tile d-sm-flex flex-column ml-2">
        <img src="@/assets/images/bavinfo/tile-ilustrations-consultation.svg" alt="" class="large-tile-img" />
        <v-card-title class="px-0 primary--text py-2 py-md-3" style="word-break: normal">
          <span class="primary--text font-weight-bold text-body-2">
            {{ $t('advantagesTileHeading') }}
          </span>
        </v-card-title>
        <v-card-text class="text-caption text-md-body-1 px-0 flex-grow-1">
          {{ $t('advantagesTileText') }}
        </v-card-text>
        <div class="v-card__cta text-right">
          <v-btn class="" color="primary" :disabled="!acceptedNecessary" depressed @click="bookingBtnPressed">
            <span class="accent--text">
              {{ $t('advantagesTileButton') }}
            </span>
          </v-btn>
        </div>
      </div>
    </v-col>

    <v-col cols="12" sm="6" md="6" lg="6" class="d-flex flex-column">
      <div class="large-tile d-sm-flex flex-column mr-2">
        <img src="@/assets/images/bavinfo/tile-llustrations-offer.svg" alt="" class="large-tile-img" />
        <v-card-title class="px-0 py-2 py-md-3 font-weight-bold text-body-2" style="word-break: normal">
          <span class="primary--text">
            {{ $t('requestOfferTileHeading') }}
          </span>
        </v-card-title>
        <v-card-text class="text-caption text-md-body-1 px-0 flex-grow-1">
          {{ $t('requestOfferTileText') }}
        </v-card-text>
        <div class="v-card__cta text-right">
          <v-btn depressed color="primary" @click="$router.push('/meine-bav?direct=false')">
            <span class="accent--text"> {{ $t('requestOfferTileButton') }}</span>
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { ref, getCurrentInstance, watch } from '@vue/composition-api';
import { mdiCloudUploadOutline, mdiCached, mdiChevronRight, mdiChevronLeft } from '@mdi/js';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const acceptedNecessary = ref(vm.$store.getters.necessaryCookies);
    const videoBookingModal = true;
    const bookingBtnPressed = () => {
      vm.$store.commit('bookConsultation/setVideoBooking', videoBookingModal);
    };

    watch(
      () => vm.$store.getters.necessaryCookies,

      () => {
        if (vm.$store.getters.necessaryCookies) {
          acceptedNecessary.value = true;
        } else {
          acceptedNecessary.value = false;
        }
      },
    );
    return {
      videoBookingModal,
      acceptedNecessary,
      bookingBtnPressed,
      // icon
      icons: {
        mdiCloudUploadOutline,
        mdiCached,
        mdiChevronRight,
        mdiChevronLeft,
      },
    };
  },
};
</script>
<style lang="scss">
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .large-tile {
    .v-card__title span {
      font-size: 19px !important;
      min-height: 44px;
    }
  }
}
</style>
<style lang="scss">
.large-tile {
  // position: relative;
  border-radius: 3px;
  margin-bottom: 3px;
  // max-height: 415px;
  // min-height: 415px;
  padding: 12px;
  padding-bottom: 16px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  flex: 1 0 auto;
  .v-card__title {
    min-height: 4px;
    flex: 0 0 auto;
    align-items: flex-start;
  }
  &-img {
    background: rgba(128, 178, 255, 0.5);
    border-radius: 3px;
    width: 100%;
    height: 190px;
    object-fit: contain;
  }
  &-title {
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    color: #2830ce;
    padding: 0 !important;
    margin-top: 32px;
    margin-bottom: 8px;
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding: 0 !important;
  }
  &-btn {
    position: absolute;
    right: 12px;
    bottom: 16px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #06fccd !important;
  }
}

@media screen and (max-width: 960px) {
  .large-tile {
    margin-left: 5px !important;
    margin-right: 5px !important;

    .v-card__cta {
      button {
        width: 100%;
      }
    }
    &-img {
      height: 160px;
    }
    &-title {
      margin-top: 13px !important;
      margin-bottom: 6px;
      font-size: 14px;
    }
    &-text {
      font-size: 12px;
      flex: 1 0 auto;
    }
    &-btn {
      width: calc(100% - 24px);
      &--offer {
        width: calc(100% - 36px);
      }
    }
  }
}
</style>
